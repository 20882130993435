import { Alert, Box, Button, Chip, Container, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Skeleton, Snackbar, Stack, Table, TableBody, TableContainer, TableHead, TextField, Typography } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Checkbox from '@mui/material/Checkbox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Apercu from './ApercuVentes';
import DateFacture from './Datefacture';
export default function FactureVentes() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));


    const [Chargement, setChargement] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [Progession, setProgession] = useState(false);
    const [valClients, setvalClients] = useState('');
    const [ListClients, setListClients] = useState([]);
    const [ListVentes, setListVentes] = useState([]);
    const [showApercu, setShowApercu] = useState(false);
    const [showDateFacture, setshowDateFacture] = useState(false);
    const [currentVente, setcurrentVente] = useState([]);
    const [Total, setTotal] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);

    const userData = useSelector(state => state.userData)
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;
  
    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);

    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setRemoveSuccess(false);
      };
    
    function formatDateToFrench(dateString) {
        const date = new Date(dateString);
      
        // Formater la date en "jour/mois/année"
        const formattedDate = date.toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      
        // Formater l'heure en "heures:minutes" sans les secondes
        const formattedTime = date.toLocaleTimeString('fr-FR', {
          hour: '2-digit',
          minute: '2-digit',
        });
      
        return `${formattedDate} à ${formattedTime}`;
      }
      

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };

      const toggleApercu = () => {
        setShowApercu(prevState => !prevState);
      };
      const toggleDatefacture = () => {
        if(selectedIds.length===0)
          {
            alert("Aucune facture sélectionné.")
          }
          else{
            setshowDateFacture(prevState => !prevState);
          }
      };
      const ShowApercu = (vente) => {
        setcurrentVente(vente);
        toggleApercu()
      }

      const getClients=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}clients/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListClients(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }


      const getVentes=async (idclient) => {
        setProgession(true);
        try {
        const response = await axios.post(`${apiUrl}list/ventesclient/${id}/${apitoken}/${idclient}`,
          {
            timeout: 20000,
          }
        );
        setListVentes(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    setProgession(false);
    }

    const handleClientChange = (event) => {
      setvalClients(event.target.value);
      getVentes(event.target.value)
    };

    const suppression = async (idvente)=> {
        setProgession(true)
        
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/vente/${id}/${apitoken}/${idvente}`,
              {
                timeout: 20000,
              }
            );
            if(response.data.status==="ok")
                {
                    const newlist=ListVentes.filter(vente => vente._id !== idvente);
                    setListVentes(newlist);
                    setRemoveSuccess(true);
                }
        } catch (error) {
          navigate('/');
        }
        setProgession(false)
    }
    

      const calculateSum = (products) => {
        return products.reduce((total, product) => {
            if(product.total === 0) {
                product.total = 0
            }
            return total + product.total;
        
        }, 0);
    };

    const formatDateToEnglish = (dateString) => {
      const [date] = dateString.split('T');
      return `${date}`;
    };

    const handleToggle = (id) => {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.includes(id)
          ? prevSelectedIds.filter((selectedId) => selectedId !== id)
          : [...prevSelectedIds, id]
      );
    };

    function ShowList() {
      
        if(ListVentes.length > 0){
          let listFiltre=ListVentes
          listFiltre = listFiltre.filter(charge => {
            const chargeDate = formatDateToEnglish(charge.date);
            const dateDebut = selectedDate
            const dateFin = selectedDate2
            return chargeDate >= dateDebut && chargeDate <= dateFin;
          });

          setTotal(calculateSum(listFiltre))
          return listFiltre.map(Ventes => {
            const isChecked = selectedIds.includes(Ventes._id);
          
            return(
              <StyledTableRow key={Ventes._id}>
              <StyledTableCell component="th" scope="row">
              <Checkbox
            checked={isChecked}
            onChange={() => handleToggle(Ventes._id)}
          />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <Typography variant="h6"  color="secondary">
            {Ventes.client["nom"]}  {Ventes.client["prenom"]}
            </Typography>
              <Box> {Ventes.client["client_code"]}</Box>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">

              <Box maxWidth={300} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {Ventes.promotions.map( promo =>{
                  return(
                    <Chip label={promo.nom_promo} variant="outlined" />
                  )
                }

                )
                }
                </Box>

              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Ventes.vendeur["nom"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Ventes.camion["designation"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Ventes.secteur["designation"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Ventes.total.toFixed(2)} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Ventes.date!=="" ? formatDateToFrench(Ventes.date) : ''}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Box>
              <Button variant="outlined" color='secondary' startIcon={<VisibilityIcon />} onClick={() => ShowApercu(Ventes)}>
              Aperçu
              </Button>
              </Box>
              </StyledTableCell>
            </StyledTableRow>
            )
      
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={9} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      useEffect(() =>{
        getClients();
        },[]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>

        <Typography variant="h4" align='center' color="primary">
        Liste des ventes
        </Typography>

        <Box display="flex" width="100%" mt={5}>
        <Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Clients</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valClients}
          label="Clients"
          onChange={handleClientChange}
        >
          {ListClients.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.nom}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>
<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px',marginLeft:'20px' }} variant="rounded" height={56} width={150} />
         :
         <TextField sx={{ marginLeft: 2 }}
         value={selectedDate}
         onChange={handleDateChange}
         id="input-with-icon-textfield"
         label="Du"
         size='normal'
         type='date'
     variant="outlined"
   />  
}
</Box>
<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px',marginLeft:'20px' }} variant="rounded" height={56} width={150} />
         :
         <TextField sx={{ marginLeft: 2 }}
         value={selectedDate2}
         onChange={handleDateChange2}
         id="input-with-icon-textfield"
         label="Au"
         size='normal'
         type='date'
     variant="outlined"
   />  
}
</Box>
        </Box>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell>Sélection</StyledTableCell>
            <StyledTableCell>Client</StyledTableCell>
            <StyledTableCell>Promotion(s)</StyledTableCell>
            <StyledTableCell>Vendeur</StyledTableCell>
            <StyledTableCell>Camion</StyledTableCell>
            <StyledTableCell>Secteur</StyledTableCell>
            <StyledTableCell>Montant</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
          <StyledTableRow>
              <StyledTableCell align='center' colSpan={9} component="th" scope="row">
              Total : {Total.toFixed(2)} Dhs
              </StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Box display="flex" width="100%" mt={3}>
      { Progession ?
      <Button style={{ marginLeft: 'auto' }} variant="contained" disabled size='large' color="success" startIcon={<NoteAddIcon/>}>
        Créer facture
        </Button> 
        :
        <Button style={{ marginLeft: 'auto' }} variant="contained" size='large' color="success" startIcon={<NoteAddIcon/>} onClick={toggleDatefacture}>
        Créer facture
        </Button> 
      }
      </Box>
<Apercu Show={showApercu} toggleApercu={toggleApercu} currentVente = {currentVente}/>
<DateFacture Show={showDateFacture} toggleApercu={toggleDatefacture} listFacture = {selectedIds}/>

<Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vente supprimée avec succès
        </Alert>
      </Snackbar>

    </Container>
  )
}
