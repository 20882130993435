import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Login from './login/Login';
import { store } from './store';
import { Provider } from 'react-redux';
import Home from './Home';
import Secure from './login/Secure';
import Setup2fa from './login/Setup2fa';
import Marques from './stock/marques/Marques';
import Categories from './stock/categories/Categories';
import Produits from './stock/produits/Produits';
import Fournisseurs from './stock/fournisseurs/Fournisseurs';
import Achats from './stock/achats/Achats';
import NouvelAchat from './stock/achats/NouvelAchat';
import EditAchat from './stock/achats/EditAchat';
import Stock from './stock/stock/Stock';
import Secteurs from './vendeurs/secteurs/Secteur';
import Vendeurs from './vendeurs/vendeurs/Vendeur';
import Camions from './vehicules/camions/Camions';
import Clients from './clients/Clients';
import Chargements from './vendeurs/chargements/Chargements';
import NouveauChargement from './vendeurs/chargements/Nouveau';
import EditChargement from './vendeurs/chargements/Edit';
import Promos from './vendeurs/promotions/Promotions';
import NouvelleVente from './pda/nouvelleVente/NouvelleVente';
import StockCamion from './stock/stockCamion/StockCamion';
import RechercheClient from './pda/nouvelleVente/clients';
import PdaPromotions from './pda/promotion/Promotions';
import PdaStock from './pda/stock/Stock';
import PDAventes from './pda/nouvelleVente/Ventes';
import Ventes from './vendeurs/ventes/Ventes';
import Boncommande from './vendeurs/boncommande/Boncommande';
import RetoursStock from './stock/retourstock/Retours';
import Nouveauretour from './stock/retourstock/Nouveauretour';
import Editretour from './stock/retourstock/Edit';
import RetoursCamion from './vendeurs/retourcamion/Retourcamion';
import NouveauretourCamion from './vendeurs/retourcamion/Nouveauretour';
import EditretourCamion from './vendeurs/retourcamion/Edit';
import PDAcredits from './pda/nouvelleVente/Credits';
import Credits from './vendeurs/credits/Credits';
import Cloturer from './vendeurs/cloture/Cloture';
import Versements from './vendeurs/versements/Versements';
import Caisse from './tresorerie/caisse/Caisse';
import Cheques from './tresorerie/cheques/Cheques';
import Factures from './facturations/factures/Factures';
import NouvelleFacture from './facturations/factures/Nouveau';
import EditFacture from './facturations/factures/Edit';
import Charges from './charges/charges/Charges';
import FactureVentes from './facturations/factures/Ventes';
import Dash from './dashboard/Dash';
import CommandeFournisseur from './stock/commandes/Commandes';
import NouvelleCommande from './stock/commandes/Nouveau';
import GratuiteStats from './stats/Gratuite';
import AnalyseVentes from './stats/AnalyseVentes';

const router = createBrowserRouter(
  createRoutesFromElements(
  <Route>
  <Route index element={<Login />} />
  <Route path="secure" element={<Secure />} />
  <Route path="setup2fa" element={<Setup2fa />} />

  <Route path="home" element={<Home />} >
  <Route index element={<Dash />} />
  <Route path="fournisseurs" element={<Fournisseurs />} />
  <Route path="marques" element={<Marques />} />
  <Route path="categories" element={<Categories />} />
  <Route path="produits" element={<Produits />} />
  <Route path="achats" element={<Achats />} />
  <Route path="nouvelachat" element={<NouvelAchat />} />
  <Route path="editachat" element={<EditAchat />} />
  <Route path="stock" element={<Stock />} />
  <Route path="secteurs" element={<Secteurs />} />
  <Route path="vendeurs" element={<Vendeurs />} />
  <Route path="camions" element={<Camions />} />
  <Route path="clients" element={<Clients />} />
  <Route path="chargements" element={<Chargements />} />
  <Route path="nouveauchargement" element={<NouveauChargement />} />
  <Route path="editchargement" element={<EditChargement />} />
  <Route path="promotions" element={<Promos />} />
  <Route path="PDArechercheclient" element={<RechercheClient />} />
  <Route path="stockcamion" element={<StockCamion />} />
  <Route path="nouvellevente" element={<NouvelleVente />} />
  <Route path="PDApromotion" element={<PdaPromotions />} />
  <Route path="PDAstock" element={<PdaStock />} />
  <Route path="PDAventes" element={<PDAventes />} />
  <Route path="ventes" element={<Ventes />} />
  <Route path="credits" element={<Credits />} />
  <Route path="boncommande" element={<Boncommande />} />
  <Route path="retourstock" element={<RetoursStock />} />
  <Route path="nouveauretour" element={<Nouveauretour />} />
  <Route path="editretourstock" element={<Editretour />} />
  <Route path="retourcamion" element={<RetoursCamion />} />
  <Route path="nouveauretourcamion" element={<NouveauretourCamion />} />
  <Route path="editretourcamion" element={<EditretourCamion />} />
  <Route path="PDAcredits" element={<PDAcredits />} />
  <Route path="cloturer" element={<Cloturer />} />
  <Route path="versements" element={<Versements />} />
  <Route path="caisse" element={<Caisse />} />
  <Route path="cheques" element={<Cheques />} />
  <Route path="factures" element={<Factures />} />
  <Route path="nouvellefacture" element={<NouvelleFacture />} />
  <Route path="editfacture" element={<EditFacture />} />
  <Route path="charges" element={<Charges />} />
  <Route path="factureventes" element={<FactureVentes />} />
  <Route path="commandesfournisseur" element={<CommandeFournisseur />} />
  <Route path="nouvellecommande" element={<NouvelleCommande />} />
  <Route path="recapgratuite" element={<GratuiteStats />} />
  <Route path="analyseventes" element={<AnalyseVentes />} />
  
  </Route>
</Route>
)
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
    <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
