import { Autocomplete, Box, Button, Container,  Skeleton,  Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import PrintIcon from '@mui/icons-material/Print';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area,PieChart, Pie, Cell } from 'recharts';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AnalyseVentes() {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));   


  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

    const userData = useSelector(state => state.userData);
    const [Chargement, setChargement] = useState(false);
    const [listClient, setlistClient] = useState([]);
    const [listProduits, setListProduits] = useState([]);
    const [listProduits2, setListProduits2] = useState([]);
    const [ProduitsData,setProduitsData] = useState([]);
    const [valClient, setvalClient] = useState(null);
    const [valProduit, setvalProduit] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [chiffreProduit,setChiffreProduit] = useState([]);
    const [PieProduits,setPieProduits]= useState([]);
    const [colorsPie, setColorsPie] = useState([]);
    const [chiffreGratuit,setChiffreGratuit] = useState([]);
    const [listSecteur, setListSecteur] = useState([]);
    const [valSecteur, setvalSecteur] = useState(null);
    const [listBenef, setListBenef] = useState(null);
    const [listCharge, setlistCharge] = useState(null);

    const [TotalVendu, setTotalVendu] = useState(0);
    const [TotalPromo, setTotalPromo] = useState(0);

    const [PiePoucentGratuit,setPiePoucentGratuit]= useState([]);

    const [listMarque, setlistMarque] = useState([]);
    const [valMarque, setvalMarque] = useState(null);

    const [listcategorie, setlistcategorie] = useState([]);
    const [valcategorie, setvalcategorie] = useState(null);

    const [promoPercentage,setpromoPercentage] = useState('');

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const todayDate = `${year}-${month}`;
  
    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);

    const [ListeVentes, setListeVentes] = useState([]);

    const handleClientChange = (event, newValue) => {

        if (newValue) {
            setvalClient(newValue.id);
        } else {
            setvalClient(null);
        }
      };

      const handleProduitChange = (event, newValue) => {

        if (newValue) {
          setvalProduit(newValue.id);
        } else {
          setvalProduit(null);
        }
      };

    const handleSecteurChange = (event, newValue) => {
        if (newValue) {
            setvalSecteur(newValue.id);
        } else {
            setvalSecteur(null);
        }
      };

      const handleMarqueChange = (event, newValue) => {
        if (newValue) {
            setvalMarque(newValue.id);
        } else {
            setvalMarque(null);
        }
      };

      const handleCategorieChange = (event, newValue) => {
        if (newValue) {
            setvalcategorie(newValue.id);
        } else {
          setvalcategorie(null);
        }
      };

      const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };

    const navigate = useNavigate();

    const debutMois= (date) => {
      const firstDayOfMonth = new Date(`${date}-01T00:00:00Z`).toISOString();
      return firstDayOfMonth
    }

    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

    const finMois= (date) => {
      const endOfMonth = new Date(new Date(`${date}-01T00:00:00Z`).getFullYear(), new Date(`${date}-01T00:00:00Z`).getMonth() + 1, 0);
      endOfMonth.setHours(23, 59, 0, 0);
      const endOfMonthISO = endOfMonth.toISOString();
      return endOfMonthISO
    }

    const getClients=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}clients/${id}/${apitoken}`,
          {
            timeout: 20000,
          }

        );
        const formattedClients = [
          { label: "Tout", id: 0 },
          ...response.data.map(client => ({
            label: `${client.nom} ${client.prenom} / ${client.client_code} / ${client.categorie}`,
            id: client._id
          }))
        ];
        setlistClient(formattedClients);
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }
  

    const getSecteurs=async () => {
        try {
        const response = await axios.post(`${apiUrl}secteurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }

        );
        const formattedSecteur = [
          { label: "Tout", id: 0 },
          ...response.data.map(item => ({
            label: `${item.designation}`,
            id: item._id
          }))
        ];
        setListSecteur(formattedSecteur)
        }
     catch (error) {
      // navigate('/');    
    }
    }

    const getMarques=async () => {
      try {
      const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
        {
          timeout: 20000,
        }

      );
      const formattedMarque = [
        { label: "Tout", id: 0 },
        ...response.data.map(item => ({
          label: `${item.designation}`,
          id: item._id
        }))
      ];
      setlistMarque(formattedMarque)
      }
   catch (error) {
    // navigate('/');    
  }
  }

    const getCategories=async () => {
      try {
      const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
        {
          timeout: 20000,
        }

      );
      const formattedCat = [
        { label: "Tout", id: 0 },
        ...response.data.map(item => ({
          label: `${item.designation}`,
          id: item._id
        }))
      ];
      setlistcategorie(formattedCat)
      }
   catch (error) {
    // navigate('/');    
  }
  }

    const transformDataForChart = (rawData) => {
      // Obtenir la liste unique des produits
      const allProducts = [...new Set(rawData.flatMap(item => item.produits.map(p => p.produit)))];
    
      // Transformer les données pour correspondre au format attendu
      const transformedData = rawData.map(item => {
        const transformedItem = { date: item.date };
    
        // Ajouter chaque produit avec sa quantité ou 0 si non vendu à cette date
        allProducts.forEach(product => {
          const productData = item.produits.find(p => p.produit === product);
          transformedItem[product] = productData ? productData.quantite_totale : 0;
        });
    
        return transformedItem;
      });
    
      return { transformedData, allProducts };
    };

    function ShowBenefice() {
      if(listBenef.length > 0){
        return listBenef.map(Item => {
          return(
            <StyledTableRow key={Item._id}>
            <StyledTableCell component="th" scope="row">
            {Item.month}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            {Item.benefice.toFixed(2)} Dhs
            </StyledTableCell>
          </StyledTableRow>
          )

        })
        }
        else {
          return (
            <StyledTableRow>
            <StyledTableCell align='center' colSpan={2} component="th" scope="row">
            Pas de données
            </StyledTableCell>
          </StyledTableRow>
          )
        }
        }

        function ShowCharge() {
          if(listCharge.length > 0){
            return listCharge.map(Item => {
              return(
                <StyledTableRow key={Item._id}>
                <StyledTableCell component="th" scope="row">
                {Item.month}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                {Item.charge.toFixed(2)} Dhs
                </StyledTableCell>
              </StyledTableRow>
              )
    
            })
            }
            else {
              return (
                <StyledTableRow>
                <StyledTableCell align='center' colSpan={2} component="th" scope="row">
                Pas de données
                </StyledTableCell>
              </StyledTableRow>
              )
            }
            }

    function ShowVentes() {
      if(ListeVentes.length > 0){
        return ListeVentes.map(Item => {
          return(
            <StyledTableRow key={Item._id}>
            <StyledTableCell component="th" scope="row">
            {Item.date}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            {Item.total_ventes.toFixed(2)} Dhs
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            {Item.count}
            </StyledTableCell>
          </StyledTableRow>
          )

        })
        }
        else {
          return (
            <StyledTableRow>
            <StyledTableCell align='center' colSpan={3} component="th" scope="row">
            Pas de données
            </StyledTableCell>
          </StyledTableRow>
          )
        }
        }

        function ShowChiffreProduits() {
          if(chiffreProduit.length > 0){
            return chiffreProduit.map(Item => {
              return(
                <StyledTableRow key={Item._id}>
                <StyledTableCell component="th" scope="row">
                {Item.designation}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                {Item.total_unite}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                {Item.chiffre_affaire_TTC.toFixed(2)} Dhs
                </StyledTableCell>
              </StyledTableRow>
              )
    
            })
            }
            else {
              return (
                <StyledTableRow>
                <StyledTableCell align='center' colSpan={3} component="th" scope="row">
                Pas de données
                </StyledTableCell>
              </StyledTableRow>
              )
            }
            }

            function ShowGratuit() {
              if(chiffreGratuit.length > 0){
                return chiffreGratuit.map(Item => {
                  return(
                    <StyledTableRow key={Item._id}>
                    <StyledTableCell component="th" scope="row">
                    {Item.designation}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    {Item.total_unites}
                    </StyledTableCell>
                  </StyledTableRow>
                  )
        
                })
                }
                else {
                  return (
                    <StyledTableRow>
                    <StyledTableCell align='center' colSpan={2} component="th" scope="row">
                    Pas de données
                    </StyledTableCell>
                  </StyledTableRow>
                  )
                }
              }
        
    const getVentes=async () => {
      setChargement(true);
      const debut = debutMois(selectedDate)
      const fin = finMois(selectedDate2)
      try {
      const response = await axios.post(`${apiUrl}statistiques/analyseventes/ventes/${id}/${apitoken}/${valClient}/${valSecteur}/${debut}/${fin}`,
        {
          timeout: 20000,
        }

      );
      setListeVentes(response.data);
      }
   catch (error) {
    // navigate('/');    
  }
  setChargement(false);
  }


  const getBenef=async () => {
    setChargement(true);
    const debut = debutMois(selectedDate)
    const fin = finMois(selectedDate2)
    try {
    const response = await axios.post(`${apiUrl}statistiques/analyseventes/benefice/${id}/${apitoken}/${debut}/${fin}`,
      {
        timeout: 20000,
      }

    );

    const chartData = Object.keys(response.data).map(key => ({
      month: key,               // Exemple : "11-2024"
      benefice: response.data[key]  // Valeur du bénéfice pour le mois
  }));
    setListBenef(chartData);
    }
 catch (error) {
  // navigate('/');    
}
setChargement(false);
}

const getCharges=async () => {
  setChargement(true);
  const debut = debutMois(selectedDate)
  const fin = finMois(selectedDate2)
  try {
  const response = await axios.post(`${apiUrl}statistiques/analyseventes/charges/${id}/${apitoken}/${debut}/${fin}`,
    {
      timeout: 20000,
    }

  );

  const chartData = Object.keys(response.data).map(key => ({
    month: key,               // Exemple : "11-2024"
    charge: response.data[key]  // Valeur du bénéfice pour le mois
}));
  setlistCharge(chartData);
  }
catch (error) {
// navigate('/');    
}
setChargement(false);
}

  const getQteProduits=async () => {
    setChargement(true);
    const debut = debutMois(selectedDate)
    const fin = finMois(selectedDate2)
    try {
    const response = await axios.post(`${apiUrl}statistiques/analyseventes/qteproduit/${id}/${apitoken}/${valClient}/${valMarque}/${valcategorie}/${debut}/${fin}`,
      {
        timeout: 20000,
      }

    );

    const { transformedData, allProducts } = transformDataForChart(response.data);

    setProduitsData(transformedData)
    setListProduits(allProducts)


    }
 catch (error) {  
}
setChargement(false);
}

const getChiffreProduit=async () => {
  setChargement(true);
  const debut = debutMois(selectedDate)
  const fin = finMois(selectedDate2)
  try {
  const response = await axios.post(`${apiUrl}statistiques/analyseventes/chiffreproduit/${id}/${apitoken}/${valClient}/${valMarque}/${valcategorie}/${debut}/${fin}`,
    {
      timeout: 20000,
    }

  );
  setChiffreProduit(response.data);

    setPieProduits(response.data)
    const generatedColors = response.data.map(() => getRandomColor());
    setColorsPie(generatedColors)

  }
catch (error) {
// navigate('/');    
console.log(error)
}
setChargement(false);
}

const getGratuite=async () => {
  setChargement(true);
  const debut = debutMois(selectedDate)
  const fin = finMois(selectedDate2)
  try {
  const response = await axios.post(`${apiUrl}statistiques/analyseventes/gratuite/${id}/${apitoken}/${valClient}/${debut}/${fin}`,
    {
      timeout: 20000,
    }

  );
  setChiffreGratuit(response.data);

  }
catch (error) {
// navigate('/');    
console.log(error)
}
setChargement(false);
}

const getTotalvente=async () => {

  const debut = debutMois(selectedDate)
  const fin = finMois(selectedDate2)
  try {
  const response = await axios.post(`${apiUrl}statistiques/analyseventes/totalvente/${id}/${apitoken}/${valProduit}/${debut}/${fin}`,
    {
      timeout: 20000,
    }

  );
  setTotalVendu(response.data.ventes)

  }
catch (error) {
// navigate('/');    
console.log(error)
}
}

const getTotalpromos=async () => {

  const debut = debutMois(selectedDate)
  const fin = finMois(selectedDate2)
  try {
  const response = await axios.post(`${apiUrl}statistiques/analyseventes/totalpromo/${id}/${apitoken}/${valProduit}/${debut}/${fin}`,
    {
      timeout: 20000,
    }

  );

  // const chartData = [
  //   { name: 'Ventes', value: response.data.total_vente },
  //   { name: 'Promotions', value: response.data.total_promo }
  // ];
  // setPiePoucentGratuit(chartData)
  // setpromoPercentage(response.data.pourcentage_promo)
  setTotalPromo(response.data.promos)

  }
catch (error) {
// navigate('/');    
console.log(error)
}
}

const getProduits=async () => {
  setChargement(true);
  try {
  const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  const formattedProd = 

    response.data.map(item => ({
      label: `${item.designation}`,
      id: item._id
    }))
  ;
  setListProduits2(formattedProd)
  }
catch (error) {

}
setChargement(false);
}


  useEffect(() =>{

      const chartData = [
    { name: 'Ventes', value: TotalVendu },
    { name: 'Promotions', value: TotalPromo }
  ];
  setPiePoucentGratuit(chartData)
  const percentage = (TotalPromo / TotalVendu) * 100;
  setpromoPercentage(percentage)

    },[TotalVendu,TotalPromo]);

    useEffect(() =>{
        getClients();
        getSecteurs();
        getMarques();
        getCategories();
        getProduits();
        },[]);

        useEffect(() =>{
          getVentes();
          getQteProduits();
          getChiffreProduit();
          getGratuite();
          getBenef();
          getCharges();
          getTotalvente();
          getTotalpromos();
          },[valClient,selectedDate,selectedDate2,valSecteur,valMarque,valcategorie,valProduit]);


  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Analyse des ventes" {...a11yProps(0)} />
          <Tab label="Analyse produits" {...a11yProps(1)} />
          <Tab label="Analyse gratuité" {...a11yProps(2)} />
          <Tab label="Bénéfices et Charges" {...a11yProps(3)} />
          <Tab label="Pourcentage gratuité" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={tabValue} index={0}>

      <Typography mt={5} variant="h4" align='center' color="primary">
        Analyse des ventes
        </Typography>

        <Box display="flex" width="100%" mt={5}>

            <Box mt={1} width={400}>
            <Autocomplete
      disablePortal
      options={listClient}
      onChange={handleClientChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Clients" />}
    />
            </Box>

            <Box mt={1} ml={2} width={200}>
            <Autocomplete
      disablePortal
      options={listSecteur}
      onChange={handleSecteurChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Secteurs" />}
    />
            </Box>

        <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Depuis"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>

          <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

          </Box>
      
          {/* <Box style={{ marginLeft: 'auto' }} mt={2}>
        <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} >
    Imprimer
  </Button>   
    </Box> */}
        </Box>

        <Box mt={5} width='50%' sx={{ float: 'left' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={ListeVentes}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* Première zone pour 'total_ventes' */}
        <Area type="monotone" dataKey="total_ventes" stroke="#8884d8" fill="#8884d8" name="Total Ventes" />
      </AreaChart>
    </ResponsiveContainer>
}

    </Box>

    <Box mt={5} width='50%' sx={{ float: 'left' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={ListeVentes}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* Deuxième zone pour 'count', renommée en "Nombre de Visites" */}
        <Area type="monotone" dataKey="count" stroke="#82ca9d" fill="#82ca9d" name="Nombre de Visites" />
      </AreaChart>
    </ResponsiveContainer>
}

    </Box>
    <Box sx={{ clear: 'both' }} />
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :

        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table  sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Chiffre d'affaire</StyledTableCell>
            <StyledTableCell>Nombre de visites</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowVentes/>
        </TableBody>
      </Table>
    </TableContainer>

}   

      </CustomTabPanel>


      <CustomTabPanel value={tabValue} index={1}>
      <Typography mt={5} variant="h4" align='center' color="primary">
        Analyse produits
        </Typography>


        <Box display="flex" width="100%" mt={5}>

            <Box mt={1} width={400}>
            <Autocomplete
      disablePortal
      options={listClient}
      onChange={handleClientChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Clients" />}
    />
            </Box>
  

              <Box mt={1} ml={2} width={200}>
            <Autocomplete
      disablePortal
      options={listMarque}
      onChange={handleMarqueChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Marques" />}
    />
            </Box>

              <Box mt={1} ml={2} width={200}>
            <Autocomplete
      disablePortal
      options={listcategorie}
      onChange={handleCategorieChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Catégories" />}
    />
            </Box>

        <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Depuis"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>

          <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

          </Box>
      
          {/* <Box style={{ marginLeft: 'auto' }} mt={2}>
        <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} >
    Imprimer
  </Button>   
    </Box> */}
        </Box>

        <Typography mt={5} variant="h4" align='center' color="secondary">
Évolution des ventes
        </Typography>
        
        <Box width="100%" mt={2}>
        <ResponsiveContainer width="100%" height={400}>
        <LineChart width={500} height={300} data={ProduitsData}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="date" />
  <YAxis />
  <Tooltip />
  <Legend />
  {listProduits.map((product,index) => (
    <Line key={product} type="monotone" dataKey={product} stroke={colorsPie[index]} />
  ))}
</LineChart>
      </ResponsiveContainer>
      </Box>

<Box>

{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :

        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table  sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produit</StyledTableCell>
            <StyledTableCell>Total unités</StyledTableCell>
            <StyledTableCell>Chiffre d'affaire</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowChiffreProduits/>
        </TableBody>
      </Table>
    </TableContainer> 
}

<Typography mt={5} variant="h4" align='center' color="secondary">
Répartition des ventes
        </Typography>

<Box width="100%" mt={2}>
      <ResponsiveContainer width="100%" height={400}>
            <PieChart width={500} height={300}>
                <Pie
                    data={PieProduits}
                    dataKey="chiffre_affaire_TTC"
                    nameKey="designation"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    label={({ name, chiffre_affaire_TTC }) => `${name}: ${chiffre_affaire_TTC} Dhs`} // Affiche "nom: valeur Dhs"
                >
                    {PieProduits.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorsPie[index]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
            </ResponsiveContainer>
        </Box>
</Box>


      </CustomTabPanel>


      <CustomTabPanel value={tabValue} index={2}>
      <Typography mt={5} variant="h4" align='center' color="primary">
        Analyse des gratuitées
        </Typography>


        <Box display="flex" width="100%" mt={5}>

            <Box mt={1} width={400}>
            <Autocomplete
      disablePortal
      options={listClient}
      onChange={handleClientChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Clients" />}
    />
            </Box>

        <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Depuis"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>

          <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

          </Box>
      
          <Box style={{ marginLeft: 'auto' }} mt={2}>
        {/* <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} >
    Imprimer
  </Button>    */}
    </Box>
        </Box>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :

        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table  sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produit</StyledTableCell>
            <StyledTableCell>Unitées</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowGratuit/>
        </TableBody>
      </Table>
    </TableContainer>

    }

    </CustomTabPanel>


    <CustomTabPanel value={tabValue} index={3}>

<Typography mt={5} variant="h4" align='center' color="primary">
  Analyse du bénéfices et des charges
  </Typography>

  <Box display="flex" width="100%" mt={5}>


  <Box  ml={2} width={200}>
    <TextField
      autoFocus
      margin="dense"
      id="date1"
      name="date"
      label="Depuis"
      type="month"
      fullWidth
      variant="outlined"
      value={selectedDate}
      onChange={handleDateChange}
    />
    </Box>

    <Box  ml={2} width={200}>
    <TextField
      autoFocus
      margin="dense"
      id="date1"
      name="date"
      label="Au"
      type="month"
      fullWidth
      variant="outlined"
      value={selectedDate2}
      onChange={handleDateChange2}
    />

    </Box>

    {/* <Box style={{ marginLeft: 'auto' }} mt={2}>
  <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} >
Imprimer
</Button>   
</Box> */}
  </Box>

  <Box mt={5} width='50%' sx={{ float: 'left' }}>
  {Chargement ? 
  <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
   :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={listBenef}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="month" />
  <YAxis />
  <Tooltip />
  <Legend />
  {/* Première zone pour 'total_ventes' */}
  <Area type="monotone" dataKey="benefice" stroke="#8884d8" fill="#8884d8" name="Bénéfice brut" />
</AreaChart>
</ResponsiveContainer>
}

</Box>

<Box mt={5} width='50%' sx={{ float: 'left' }}>
  {Chargement ? 
  <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
   :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={listCharge}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="month" />
  <YAxis />
  <Tooltip />
  <Legend />
  {/* Deuxième zone pour 'count', renommée en "Nombre de Visites" */}
  <Area type="monotone" dataKey="charge" stroke="#82ca9d" fill="#82ca9d" name="Charges" />
</AreaChart>
</ResponsiveContainer>
}

</Box>
<Box sx={{ clear: 'both' }} />
  {Chargement ? 
  <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
   :
    <>
   <Box mt={2} width='48%' sx={{ float: 'left' }}>   
  <TableContainer component={Paper}>

<Table  sx={{ width: '100%' }} aria-label="customized table">
  <TableHead>
    <TableRow>
      <StyledTableCell>Date</StyledTableCell>
      <StyledTableCell>Bénéfice brut</StyledTableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <ShowBenefice/>
  </TableBody>
</Table>
</TableContainer>
</Box>

<Box mt={2} ml={4} width='48%' sx={{ float: 'left' }}>   
  <TableContainer component={Paper}>

<Table  sx={{ width: '100%' }} aria-label="customized table">
  <TableHead>
    <TableRow>
      <StyledTableCell>Date</StyledTableCell>
      <StyledTableCell>Charges</StyledTableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <ShowCharge/>
  </TableBody>
</Table>
</TableContainer>
</Box>

<Box sx={{ clear: 'both' }} />
</>
}   

</CustomTabPanel>

<CustomTabPanel value={tabValue} index={4}>


<Box display="flex" width="100%" mt={5}>

<Box mt={1} width={400}>
<Autocomplete
disablePortal
options={listProduits2}
onChange={handleProduitChange}
fullWidth
renderInput={(params) => <TextField {...params} label="Produits" />}
/>
</Box>

<Box  ml={2} width={200}>
<TextField
autoFocus
margin="dense"
id="date1"
name="date"
label="Depuis"
type="month"
fullWidth
variant="outlined"
value={selectedDate}
onChange={handleDateChange}
/>
</Box>

<Box  ml={2} width={200}>
<TextField
autoFocus
margin="dense"
id="date1"
name="date"
label="Au"
type="month"
fullWidth
variant="outlined"
value={selectedDate2}
onChange={handleDateChange2}
/>

</Box>

<Box style={{ marginLeft: 'auto' }} mt={2}>
{/* <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} >
Imprimer
</Button>    */}
</Box>
</Box>


<Typography mt={5} variant="h4" align='center' color="primary">
        Pourcentage gratuité
        </Typography>
<Box width="100%" mt={2}>
      <ResponsiveContainer width="100%" height={400}>
            <PieChart width={500} height={300}>
                <Pie
                    data={PiePoucentGratuit}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    label={(entry) => {
                      // Si c'est la section "Promotions", afficher le pourcentage
                      if (entry.name === 'Promotions') {
                        return `${entry.name} (${promoPercentage.toFixed(2)}%)`;
                      }
                      return entry.name;
                    }}
                >
                    {PieProduits.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorsPie[index]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
            </ResponsiveContainer>
        </Box>
</CustomTabPanel>


        </Container>
  
   
  
  )
}
